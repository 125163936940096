.main-header {
    display: flex;
    position: relative;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 1000px;
}

.main-domains-text {
    color: #FFFFFF;
}

.main-header-text {
    margin: 0;
}

.main-title {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    align-content: center;
}

.main-title > p {
    margin: 0;
}

.main-title-word:last-child::after {
    content: ".";
    background: linear-gradient(267.67deg, #00C0DC 0%, #00DFD2 99.59%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.main-title-word-color {
    text-transform: uppercase;
    background: linear-gradient(267.67deg, #00C0DC 0%, #00DFD2 99.59%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.main-title-word-underlined {
    border-bottom: 1px #00C0DCFF solid;
}

.main-header-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    min-height: 916px;
    max-width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
    max-height: 100svh;
}

.main-header-menu {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 112px;
}

.header-nav a,
.header-nav div {
    color: #fff !important;
}

.header-nav .option {
    color: #000 !important;
}

.header-nav div::after {
    background: #fff;
}

.main-header-nav {
    transition: 30s ease-in;
    display: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: right;
}

.main-header-nav a {
    color: #000;
    font-size: 16px;
    font-weight: 400;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    color: whitesmoke;
    outline: none;
}

.domains-parent {
    position: relative;
    width: fit-content;
    color: black;
    font-size: 16px;
    font-weight: 400;
    z-index: 1;
}

.domains-parents .link {
    font-weight: 400;
    font-size: 16px;
}

.select {
    position: absolute;
    top: 30px;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px !important;
    border-radius: 8px;
    background: #FFF;
    color: black;
    z-index: 2;
    text-align: left;
    box-shadow: 1px 2px 12px -6px rgba(0, 0, 0, 0.82);
    padding: 10px 0;
    transition: all 1s ease-in-out;
}

.option {
    border-radius: 8px;
    border: 0;
    color: #5b5b5b;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    width: 200px;
    padding: 5px 16px;
}

.option:hover {
    background: #FAFAFA;
    color: #000;
}

.link {
    font-family: 'DM Sans', sans-serif;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
    position: relative;
}

.link:hover, .option:hover {
    cursor: pointer;
}

.link:hover::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 1px;
    border-radius: 2px;
    bottom: -4px;
    left: 0;
    background: #00C0DC ;
    transition: width 0.3s ease-in-out;
    width: 100%;
}

.link::after {
    content: " ";
    position: absolute;
    width: 0;
    height: 1px;
    border-radius: 2px;
    bottom: -4px;
    left: 0;
    background: #00C0DC ;
    transition: width 0.3s ease-in-out;
}

.header-nav a, .header-nav div {
    color: #fff !important;
}

.header-nav .link::after {
    background: #fff;
}

.header-nav .link:hover::after {
    width: 100%;
}

.header-nav .active-a::after {
    background: #fff;
}

.active-a {
    position: relative;
    font-family: 'DM Sans', sans-serif;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
}

.active-a::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 1px;
    border-radius: 2px;
    bottom: -4px;
    left: 0;
    background: #00C0DC;
}

.contact-link {
    font-weight: 400 !important;
}

.main-header-nav a:nth-last-child(-n+1) {
    text-transform: capitalize;
    color: #0E100F;
    padding: 17px 40px;
    border-radius: 8px;
    background: linear-gradient(267.67deg, #00C0DC 0%, #00DFD2 99.59%);
    font-weight: 700;
    font-size: 16px;
    transition: color 0.2s ease-in-out;
    width: max-content;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.main-header-nav a:nth-last-child(-n+1)::before, .language-dropdown a::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 192, 220, 1) 0%, rgba(0, 210, 198, 1) 100%);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    z-index: -1;
    border-radius: 8px;
}

.main-header-nav a:nth-last-child(-n+1):hover::before, .language-dropdown a:hover::before {
    opacity: 1;
}

.main-header-nav a:nth-last-child(-n+1):hover, .language-dropdown a:hover {
    color: #FFFFFF;
    transition: color 0.2s ease-in-out;
}

.main-header-sect-1 {
    width: 100%;
    height: 100%;
    color: #000000;
    font-size: 24px;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.main-header-sect-1-content {
    grid-column: span 7;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.main-header-sect-1-image {
    width: 100%;
    height: 30%;
    background-image: url("https://res.cloudinary.com/dc4kiugor/image/upload/v1716910608/Frame_25_c4b9bn.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 12px 0;
}

.main-header-sect-1 p:nth-child(2), .main-header-sect-1 div:nth-child(2), .main-sect-title {
    font-size: 40px;
    font-weight: 700;
}

.main-buttons {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.main-links {
    display: flex;
    gap: 50px;
}

.main-clutch {
    height: 60px;
    width: 250px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    background-image: url("https://res.cloudinary.com/dc4kiugor/image/upload/v1716917051/Clutch_utcl6f.svg");
}

.main-upwork {
    height: 60px;
    width: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    background-image: url("https://res.cloudinary.com/dc4kiugor/image/upload/v1716917051/Frame_29_whmukp.svg");
}

.main-color-btn {
    text-transform: capitalize;
    color: #0E100F;
    padding: 17px 40px;
    border-radius: 8px;
    background: linear-gradient(267.67deg, #00C0DC 0%, #00DFD2 99.59%);
    font-weight: 700;
    font-size: 16px;
    transition: color 0.2s ease-in-out;
    width: max-content;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.main-color-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 192, 220, 1) 0%, rgba(0, 210, 198, 1) 100%);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    z-index: -1;
    border-radius: 8px;
}

.main-color-btn:hover::before {
    opacity: 1;
}

.main-color-btn:hover {
    color: #FFFFFF;
    transition: color 0.2s ease-in-out;
}

.main-light-btn {
    text-transform: capitalize;
    border: solid 2px transparent;
    border-radius: 8px;
    background-image: linear-gradient(#00C0DC, #00D2C6), linear-gradient(101deg, #00C0DC, #00D2C6);
    box-shadow: 2px 1000px 1px #fff inset;
    padding: 16px 40px;
    font-weight: 700;
    font-size: 16px;
    color: #0E100F;
    transition: all 0.2s ease-in-out;
    width: max-content;
    fill: #0E100F;
}

.dropdown-toggle::after {
    display: none !important;
}

.main-header-sect-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-header-resources {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.main-header-resources svg {
    fill: #FFFFFF;
    width: 32px;
    height: 32px;
    transition: fill 0.2s ease-in-out, background-color 0.2s ease-in-out;
    border-radius: 5px;
}

.main-header-resources svg:hover {
    fill: #0E100F;
    background-color: rgba(0, 210, 198, 1);
    border: 0;
    width: 32px;
    height: 32px;
}
.main-header-resources svg path {
    align-self: center;
}

.main-header-languages {
    display: flex;
    width: fit-content;
    gap: 20px;
    color: #FFFFFF;
    cursor: pointer;
}

.main-header-languages p {
    margin: 0;
}

.main-header-languages p:hover {
    font-weight: 700;
    transition: 0.2s ease-in-out;
}

.main-sect-text {
    max-width: 960px;
    margin-top: 24px;
}

.domains-cards-item {
    height: 260px;
}

.service-cards-item {
    height: 312px;
}

.portfolio-card-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    border-radius: 0 !important;
    padding: 0 !important;
}

.service-cards-item {
    font-family: "Lora", serif;
}

.active-domain {
    height: 296px;
    background: linear-gradient(180deg, rgba(0, 192, 220, 1) 0%, rgba(0, 210, 198, 1) 100%);
    color: #FFFFFF !important;
}

.active-service {
    height: 364px;
    background: linear-gradient(180deg, rgba(0, 192, 220, 1) 0%, rgba(0, 210, 198, 1) 100%) !important;
    color: #FFFFFF !important;
}

.sect-article {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.mobile-map {
    display: none;
    width: 100%;
    height: auto;
    margin: 15px auto 0 auto;
}

.map {
    width: 478px;
    position: relative;
}

.map-img {
    width: 478px;
}

.map-dot-bg {
    position: absolute;
    width: 14px;
    height: 14px;
    background: rgb(255, 166, 25);
    background: -moz-radial-gradient(circle, rgba(255, 166, 25, 1) 20%, rgba(255, 255, 255, 1) 47%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-radial-gradient(circle, rgba(255, 166, 25, 1) 20%, rgba(255, 255, 255, 1) 47%, rgba(255, 255, 255, 1) 100%);
    background: radial-gradient(circle, rgba(255, 166, 25, 1) 20%, rgba(255, 255, 255, 1) 47%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffa619", endColorstr="#ffffff", GradientType=1);
    opacity: 85%;
    border-radius: 50%;
    top: -5.5px;
    left: -5.15px;
    z-index: 1;
}

.map-dot {
    position: absolute;
    border-radius: 50%;
    transition: all .2s ease-in-out;
    z-index: 3;
}

.map-dot:hover {
    transform: scale(1.3);
}

.map-dot:hover::after {
    font-weight: 700;
    font-size: 12px;
    position: absolute;
    top: -128px;
    left: 5px;
}

.map-dot:hover .map-dot-bg::after {
    position: absolute;
    bottom: 9px;
    left: 6px;
    content: url("https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535378/Main/Country_row_p4hw2z.svg");
    transform: rotate(360deg);
}

#USA-en, #USA-ua {
    height: 10px;
    left: 46px;
    top: 82px;
    width: 10px;
}

#USA-en:hover::after {
    content: "USA";
}

#USA-ua:hover::after {
    content: "США";
}

#France-en, #France-ua {
    left: 209.5px;
    top: 64.4px;
}

#France-en:hover::after {
    content: "France";
}

#France-ua:hover::after {
    content: "Франція";
}

#Austria-en, #Austria-ua {
    left: 223.5px;
    top: 64px;
}

#Austria-en:hover::after {
    content: "Austria";
}

#Austria-ua:hover::after {
    content: "Австрія";
}

#Belgium-en, #Belgium-ua {
    left: 215px;
    top: 55px;
}

#Belgium-en:hover::after {
    content: "Belgium";
}

#Belgium-ua:hover::after {
    content: "Бельгія";
}

#German-en, #German-ua {
    left: 228px;
    top: 54px;
}

#German-en:hover::after {
    content: "German";
}

#German-ua:hover::after {
    content: "Німеччина";
}

#Ukraine-en, #Ukraine-ua {
    right: 217px;
    top: 56px;
}

#Ukraine-en:hover::after {
    content: "Ukraine";
}

#Ukraine-ua:hover::after {
    content: "Україна";
}

#Romania-en, #Romania-ua {
    right: 225px;
    top: 64px;
}

#Romania-en:hover::after {
    content: "Romania";
}

#Romania-ua:hover::after {
    content: "Румунія";
}

#Israel-en, #Israel-ua {
    right: 203px;
    top: 79px;
}

#Israel-en:hover::after {
    content: "Israel";
}

#Israel-ua:hover::after {
    content: "Ізраїль";
}

#India-en, #India-ua {
    height: 7px;
    right: 135px;
    top: 110px;
    width: 7px;
}

#India-en:hover .map-dot-bg::after,
#India-ua:hover .map-dot-bg::after {
    position: absolute;
    top: 13px;
    content: url("https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535380/Main/India_row_uzxxfr.svg");
}

#India-en:hover::after {
    content: "India";
    bottom: -80px;
    top: unset;
}

#India-ua:hover::after {
    content: "Індія";
    bottom: -80px;
    top: unset;
}

#Australia-en, #Australia-ua {
    height: 10px;
    width: 10px;
    bottom: 53px;
    right: 48px;
}

#Australia-en:hover .map-dot-bg::after,
#Australia-ua:hover .map-dot-bg::after {
    content: url("https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535380/Main/India_row_uzxxfr.svg");
    bottom: 13px;
    left: 5px;
    transform: rotate(180deg);
}

#Australia-en:hover::after {
    content: "Australia";
    top: unset;
    bottom: 79px;
}

#Australia-ua:hover::after {
    content: "Австралія";
    top: unset;
    bottom: 79px;
}

#UK-en, #UK-ua {
    left: 205px;
    top: 44px;
}

#UK-en:hover .map-dot-bg::after,
#Sweden-en:hover .map-dot-bg::after,
#UK-ua:hover .map-dot-bg::after,
#Sweden-ua:hover .map-dot-bg::after {
    content: url("https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535380/Main/India_row_uzxxfr.svg");
    bottom: 13px;
    transform: rotate(180deg);
}

#UK-en:hover::after {
    content: "UK";
    top: -88px;
}

#UK-ua:hover::after {
    content: "Велика Британія";
    top: -88px;
    white-space: nowrap;
}

#Sweden-en, #Sweden-ua {
    left: 227px;
    top: 37px;
}

#Sweden-en:hover::after {
    content: "Sweden";
    top: -88px;
}

#Sweden-ua:hover::after {
    content: "Швеція";
    top: -88px;
}

.article-info {
    max-width: 388px;
}

.article-heading {
    color: #0E100F;
    font-size: 32px;
    font-weight: 600;
    font-family: 'Lora', serif;
}

.counter {
    width: 100%;
    margin: 0 auto;
}

.counter-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 80px 0;
    gap: 20px;
}

.counter-item {
    display: flex;
    flex-direction: column !important;
    gap: 16px;
    align-items: center;
    font-family: 'DM Sans', sans-serif;
}

.counter-number {
    font-weight: 700;
    font-size: 64px;
    color: #0E100F;
    pointer-events: none;
    margin: 0;
    line-height: 78px;
    width: 240px;
    display: block;
    text-align: center;
}

.counter-text {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #4F4F4F;
    margin: 0;
    line-height: 30px;
}

.stars {
    display: flex;
}

.stars img {
    height: 22px;
}

.main-sect-form {
    display: block;
    width: 100%;
    margin: 0 auto;
}

.contactus-title {
    font-size: 40px;
    font-weight: 700;
    color: #0E100F;
    font-family: 'Lora', serif;
    margin-bottom: 84px;
}

.contactus-plain-text {
    font-size: 28px;
    font-weight: 700;
    color: #4F4F4F;
    font-family: 'DM Sans', sans-serif;
    max-width: 450px;
    margin-bottom: 40px;
}

.contactus-highlight {
    color: #00C0DC;
}

.main-form {
    display: flex;
    justify-content: center;
    width: 100%;
}

.main-form-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 470px;
    align-self: end;
}

.main-sect-form-label {
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: 'DM Sans', sans-serif;
}

.contact-us-email {
    background: transparent;
    border: #00C0DC 1px solid;
    margin-top: 8px;
    height: 51px;
}

.contact-us-email:active, .contact-us-email:focus {
    border: #00C0DC 2px solid;
}

.error-message {
    color: rgb(188, 0, 0);
    font-size: 0.9em;
    margin-top: 0.25em;
    display: block;
    position: absolute;
    bottom: -20px;
}

.error-message:hover {
    display: block;
    opacity: 100%;
}

.form-button {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    border: none;
    background: linear-gradient(180deg, rgba(0, 192, 220, 1) 0%, rgba(0, 210, 198, 1) 100%);
    color: #0E100F;
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
}

.main-sect-form form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.main-sect-form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.main-sect-form textarea {
    width: 100%;
    height: 100px;
    font-family: "DM Sans", sans-serif;
}

.main-sect-form button {
    align-self: center;
}

.active-language {
    position: relative;
    font-weight: 700;
}

.active-language::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 2px;
    bottom: -5px;
    left: 0;
    background: #FFFFFF;
}

.view-all-btn {
    min-width: 150px !important;
}

.view-all-btn:hover {
    cursor: pointer;
}

.main-header-arrow {
    position: absolute;
    left: 50%;
}

.main-title-wrapper {
    display: flex;
    align-items: center;
}

.counter-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media (min-width: 375px) and (max-width: 960px) {
    .main-header {
        min-height: 1090px;
    }

    .main-header-container {
        padding: 0 24px 24px;
    }

    .main-header-menu {
        height: 92px;
    }

    .main-header-arrow svg, .main-header-resources svg {
        width: 24px;
        height: 24px;
    }

    .main-header-sect-2 {
        position: relative;
        bottom: 30px;
    }

    .main-header-resources {
        gap: 20px;
    }

    .main-header-arrow {
        position: absolute;
        left: 50%;
        bottom: -45px;
    }

    .main-sect-form-row {
        flex-direction: column;
    }

    .main-sect-form-row input {
        width: 100%;
    }

    .map {
        display: none;
        margin-top: 100px;
        margin-left: auto;
        margin-right: auto;
    }

    .mobile-map {
        display: block;
    }

    .counter-wrapper {
        justify-content: space-evenly;
    }

    .main-title-wrapper {
        height: 200px;
    }

    .main-title-wrapper-ua {
        height: 290px;
    }

    .main-form-content {
        align-self: center;
    }
}

@media (min-width: 540px) {
    .main-title-wrapper, .main-title-wrapper-ua {
        height: 160px;
    }

    .main-buttons {
        flex-direction: row;
        gap: 30px;
    }
}

@media (min-width: 960px) {
    .main-sect-form {
        display: flex;
        justify-content: space-between;
        gap: 40px;
    }

    .main-form {
        max-width: 590px;
    }

    .sect-article {
        flex-direction: row;
        justify-content: space-between;
    }

    .article-info {
        max-width: 60%;
    }
}

@media (min-width: 960px) and (max-width: 1200px) {
    .main-header-container {
        padding: 0 60px 60px;
    }

    .main-header-languages {
        gap: 40px;
    }

    .map {
        margin-left: auto;
        margin-right: auto;
    }

    .counter-wrapper {
        justify-content: space-evenly;
    }

    .main-header-sect-1-image {
        height: 40%;
    }

    .main-title-wrapper {
        height: 116px;
    }
}

@media (min-width: 1200px) {
    .main-header-container {
        padding: 0 120px 60px;
        justify-content: space-between;
    }

    .main-header-sect-1 {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 10px;
        height: auto;
    }

    .main-header-sect-1-image {
        grid-column: span 5;
        grid-row: span 2;
        transform: scale(120%) translateX(40px) translateY(60px);
        background-position: right top;
        height: 100%;
        margin: 0;
    }

    .main-header-sect-1-content {
        gap: 50px;
    }

    .main-buttons {
        flex-direction: row;
        gap: 30px;
    }

    .main-links {
        margin-top: 100px;
        grid-column: span 7;
    }

    .main-header-sect-1 p:nth-child(2), .main-header-sect-1 div:nth-child(2), .main-sect-title {
        font-size: 58px;
    }

    .main-header-languages {
        gap: 40px;
    }

    .main-sect-container {
        width: 960px;
        padding: 0;
    }

    .domains-cards-item:focus {
        box-sizing: border-box;
        justify-content: flex-start;
        padding-top: 28px;
        height: 296px;
    }

    .main-sect-form {
        width: 100%;
    }

    .main-sect-form-row input {
        flex: 1;
        max-width: unset;
    }

    .counter-wrapper {
        flex-wrap: nowrap;
        justify-content: unset;
        gap: 0;
    }

    .counter-block {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }

    .counter-text {
        text-align: center;
        white-space: pre;
    }

    .main-title-wrapper {
        height: 260px;
    }

    .main-title-wrapper-ua {
        height: 310px;
    }

    .article-info {
        font-size: 20px;
        font-weight: 400;
        line-height: 38px;
    }
}

@media (min-width: 1350px) {
    .counter-wrapper {
        gap: 90px;
    }

    .main-title-wrapper {
        height: 160px;
    }

    .main-title-wrapper-ua {
        height: 260px;
    }

    .main-header-nav {
        display: flex;
        justify-content: flex-end;
        flex-wrap: nowrap;
        font-size: 14px;
    }

    #dropdown-basic {
        display: none;
    }

    .article-info {
        font-size: 20px;
        line-height: 40px;
    }
}

@media (min-width: 1860px) {
    .sect-article {
        justify-content: space-evenly;
    }
}