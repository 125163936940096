.article-card {
    display: flex;
    flex-direction: column;
    height: 340px !important;
    width: 307px;
    gap: unset;
    background-color: #FAFAFA;
}

.article-card .article-card-text .article-card-footer .article-card-btns .article-card-btn:not(:first-child) svg path  {
    transition: all ease-in-out 0.3s;
}

.article-card:hover .article-card-text .article-card-footer .article-card-btns .article-card-btn:not(:first-child) svg path {
    fill: #FFFFFF;
}

.article-card-img {
    margin: 0 !important;
    position: unset !important;
    height: 200px !important;
    width: 307px !important;
}

.article-card-img img {
    height: 200px !important;
    width: 307px !important;
}

.article-card p {
    position: unset !important;
    white-space: pre-wrap;
    text-align: left;
}

.article-card-heading {
    font-size: 18px;
    margin-bottom: 0;
    color: #0E100F;
    font-weight: 700;
}

.article-card-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 24px 20px;
}

.article-card-time {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 12px;
}

.article-card-time p {
    position: relative;
    width: fit-content;
    font-weight: 400;
    font-size: 14px;
    color: #4f4f4f;
    margin: 0;
}

.article-card-date::after {
    position: absolute;
    content: ".";
    color: #4f4f4f;
    top: -3px;
    right: -7px;
}

.portfolio-carousel-item {
    width: 100%;
    background-color: #FAFAFA;
    border-radius: 20px;
    height: 100%;
}

.article-card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}

.article-card-btns {
    display: flex;
    gap: 8px;
}

.article-card-btn {
    cursor: pointer;
}