.language-changer {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    z-index: 1;
}

.current-language {
    display: flex;
    align-items: center;
    gap: 8px;
}

.current-language p span {
    font-weight: 600;
    margin-left: 8px;
}

.current-language svg {
    width: 24px;
    height: 24px;
    fill: black;
}

.current-language.domains-burger svg {
    fill: white;
}

.arrow-white svg {
    width: 24px;
    height: 24px;
    fill: white;
}

.header-dropdown-lang .header-dropdown-container, .header-dropdown-domains {
    display: unset;
}

.header-dropdown-lang .language-changer {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
}

.header-dropdown-lang .language-changer .language-dropdown {
    margin: 8px 0 0 12px;
}

.header-dropdown-item .language-changer {
    padding: 8px 0 8px 12px;
}

.header-dropdown-item .header-dropdown-container {
    position: relative !important;
}

.header-dropdown-item:first-of-type {
    z-index: 2;
}

.header-dropdown-domains {
    position: absolute !important;
    left: 12px !important;
}

.header-dropdown-container .language-dropdown, .header-dropdown-domains {
    position: unset;
}

.language-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: max-content;
    z-index: 10;
    border-radius: 8px;
}

.header-dropdown {
    position: absolute !important;
    left: unset;
    right: 0;
    width: 150px;
}

.header-dropdown-lang .language-changer {
    padding: 8px 12px;
}

.language-dropdown a, .language-dropdown p {
    padding: 8px 12px !important;
    background: none !important;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
    display: block;
    color: inherit;
    text-decoration: none;
    position: relative;
}

.language-dropdown svg {
    width: 24px;
    height: 24px;
    transition: fill 0.3s;
    padding: 0;
}

.language-dropdown a:hover svg,
.language-dropdown a:focus svg {
    fill: #0044cc;
}
