@import "/src/Assets/Styles/Main.css";
@import "/src/Assets/Styles/Footer.css";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&family=Lora:wght@400;500;600;700&display=swap');

@font-face {
    font-display: swap;
}

html {
    scroll-behavior: auto !important;
    font-family: 'DM Sans', sans-serif;
}

body {
    margin: 0 auto;
    -ms-overflow-style: none;
    user-select: none;
}

body::-webkit-scrollbar {
    width: 0;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

a {
    text-decoration: none !important;
    color: #FFFFFF;
    white-space: pre;
}


nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 40px;
}

.btn-primary {
    background: none !important;
    border: none !important;
    fill: #FFFFFF;
}

.btn-link {
    color: #0E100F !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}

.btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}

.dropdown-item {
    color: #0E100F !important;
}

.dropdown-item a {
    color: #0E100F !important;
}

.dropdown-item:hover {
    background: none !important;
    transition: 0.2s ease-in-out;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}

.sect {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;
    padding: 100px 0;
    color: #4F4F4F;
    position: relative;
}

.sect-dark {
    background-color: rgba(0, 26, 65, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.sect-dark-flora {
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.hidden-sect {
    height: 0;
    padding: 0 !important;
}

.sect:first-child {
    padding: 100px 0 0 0;
}

.sect:nth-child(odd) {
    background-color: #FAFAFA;
}

.sect-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0 119.5px;
    width: 100%;
    max-width: 1440px;
}

.iot-sect-container-none {
    display: none;
}

.whiteBg {
    background: #FFFFFF !important;
}

.beigeBg {
    background: #FAFAFA !important;
}

.contact-us-div {
    width: 100%;
    background: #FFFFFF !important;
}

.sect-title {
    display: flex;
    flex-wrap: wrap !important;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 1.44px;
    color: #0E100F;
    font-family: 'Lora', serif;
}

.sect-title p {
    margin: 0;
}

.sect-subtitle {
    margin-bottom: 50px;
    font-size: 16px;
    font-weight: 400;
    color: #4F4F4F;
}

.slider-title {
    margin-bottom: 50px;
    align-items: flex-start;
    gap: 20px;
}

.sect-cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.sect-cards-2 {
    margin-top: 20px;
}

.sect-cards-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    width: 387px;
    height: 300px !important;
    font-size: 20px;
    font-weight: 700;
    background-color: #FAFAFA;
    color: #0E100F !important;
    transition: background-color 0.2s ease, color 0.2s ease;
    border-radius: 12px;
    overflow: hidden;
}

.sect-cards-card-careers {
    justify-content: center;
}

.sect-cards-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 192, 220, 1) 0%, rgba(0, 210, 198, 1) 100%);
    opacity: 0;
    transition: opacity 0.2s ease;
    z-index: 0;
}

.sect-cards-card:hover::before {
    opacity: 1;
}

.sect-cards-card:hover {
    color: #FFFFFF !important;
}

.sect-cards-card > * {
    position: relative;
    z-index: 1;
}

.iot-sect-cards-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 33px;
    position: relative;
    max-width: 100% !important;
    width: 590px;
    height: 100%;
    font-size: 20px;
    font-weight: 700;
    background-color: #FAFAFA;
    color: #0E100F !important;
    border-radius: 12px;
    overflow: hidden;
    padding: 30px;
    background-image: linear-gradient(to right, #FAFAFA, #FAFAFA);
    z-index: 1;
    box-sizing: border-box;
}

.iot-div-header {
    font-size: 30px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 140% !important;
    font-family: 'DM Sans', sans-serif !important;
}

.iot-sect-cards-card::before {
    max-width: 100%;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(268deg, #00C0DC 0%, #00DFD2 99.59%);
    z-index: -1;
    transition: opacity 0.2s linear;
    opacity: 0;
}

.iot-sect-cards-card:hover::before {
    opacity: 1;
}

.iot-sect-cards-card:hover {
    box-shadow: 0 4px 12px 0 rgba(33, 150, 83, 0.25);
}

.iot-sect-cards-card:hover .iot-svg-container {
    transition: background 0.2s linear;
    background: #FFFFFF;
}

.iot-sect-cards-card:hover .iot-tech-container .iot-dot {
    background: #FFF;
}

.iot-div:first-of-type {
    margin-bottom: auto;
}

.iot-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    white-space: pre-wrap;
}

.iot-svg-container {
    width: 26px;
    height: 26px;
    border-radius: 26px;
    background: linear-gradient(268deg, #00C0DC 0%, #00DFD2 99.59%);
}

. {
    margin-top: 20px;
    color: #0E100F;
    font-family: 'DM Sans', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.iot-div-text {
    color: #0E100F;
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    white-space: pre-wrap;

}

.iot-div-bold-text {
    color: #0E100F;
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.iot-tech-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
}

.tech-container {
    display: flex;
    gap: 3px;
}

.iot-dot {
    width: 8px;
    height: 8px;
    background: #009284;
    border-radius: 100%;
    transition: all ease-in-out 0.2s;
}

.iot-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.iot-button {
    width: 220px !important;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    border: solid 1px transparent;
}

.sect-cards-card-art {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 307px;
    height: 340px !important;
    background-color: #FFFFFF;
    color: #0E100F !important;
    transition: background 1s ease;
}

.articles-container {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
}

.article-card-time {
    display: flex;
    gap: 10px;
}

.articles-p {
    max-width: 100%;
    width: 230px;
    height: fit-content;
    font-weight: 700;
    font-size: 18px;
    line-height: 123.4%;
    white-space: pre-wrap;
    overflow: hidden;
}

.bg-gray {
    background-color: #FAFAFA;
}

.bg-white {
    background-color: #FFFFFF !important;
}

.sect-cards-card p {
    text-align: center;
    bottom: 10px;
}

.sect-cards-card .project-mini-card-title {
    position: static !important;
    margin: 0 !important;
}

.portfolio-card-wrapper {
    height: auto !important;
    padding-bottom: 24px !important;
}

.portfolio-card-swiper {
    width: 100% !important;
    background-color: #ffffff;
    margin: 0 auto;
}

.portfolio-img {
    width: 100% !important;
    object-fit: cover;
    max-height: 166px;
    height: 100%;
    margin: 0 !important;
}

.sect-cards-card img {
    height: 180px;
    width: auto;
}

.articles-img {
    height: 200px !important;
    width: 307px;
    object-fit: contain;
}

.sect-cards-card span {
    font-size: 16px;
    font-weight: 400;
    white-space: unset;
    color: #4f4f4f !important;
}

button {
    box-sizing: border-box;
    text-align: center;
    border: none;
    background: #FFFFFF;
    color: #0E100F;
    font-weight: 700;
    font-size: 16px;
}

.sect-button, .success-message {
    padding: 12px 20px;
    border: solid 2px transparent;
    border-radius: 8px;
    background-image: linear-gradient(#00C0DC, #00D2C6), linear-gradient(101deg, #00C0DC, #00D2C6);
    box-shadow: 2px 1000px 1px #fff inset;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.sect-button:hover:enabled, .main-light-btn:hover {
    background: linear-gradient(180deg, rgba(0, 192, 220, 1) 0%, rgba(0, 210, 198, 1) 100%);
    color: #FFFFFF;
    background-origin: unset;
    background-clip: unset;
    box-shadow: none;
    transition: all 0.2s ease-in-out;
    fill: #FFFFFF;
}

.sect-button:hover:enabled .btn-link {
    color: #FFFFFF !important;
}

button:disabled,
button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    background: #cccccc;
    color: #666666;
}

input {
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    padding: 12px 24px;
    border: none;
    border-radius: 6px;
    background: #FAFAFA;
    color: #828282;
    max-width: unset;
}

input:focus, textarea:focus {
    outline: none;
    border: 1px solid;
    border-image: -webkit-linear-gradient(45deg, #00C0DC, #00D2C6) 20 stretch;
}

textarea {
    box-sizing: border-box;
    padding: 12px 24px;
    resize: none !important;
    border: none;
    background: #FAFAFA;
    color: #828282;
    border-radius: 6px;
}

.carousel-cards-item {
    display: flex !important;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 307px !important;
    height: 312px;
    background: #FFFFFF;
}

.carousel-cards-item:hover {
    background: linear-gradient(180deg, rgba(0, 192, 220, 1) 0%, rgba(0, 210, 198, 1) 100%);
    color: #FFFFFF;
    transition: fill 0.3s ease-in-out;
}

.success-message {
    align-self: center;
    width: fit-content;
    height: fit-content;
    font-weight: 600;
}

.h-10vh {
    min-height: 100vh;
}


.carousel-wrap {
    position: relative;
}

.b-refs-buttons {
    position: absolute;
    top: 50%;
    left: -5%;
    width: 110%;
    display: flex;
    justify-content: space-between;
}

.b-refs-buttons button {
    background: none !important;
}

.carousel-center {
    display: flex;
    justify-content: center;
}

.slider-arrow {
    width: 20px;
    height: 36px;
}

.swiper-button-next:not(:disabled), .swiper-button-prev:not(:disabled) {
    color: #828282;
    cursor: pointer;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    width: 40px;
    transition: color 0.2s ease-in-out;
}

.swiper-button-next:not(:disabled) {
    justify-content: end;
}

.swiper-button-prev:not(:disabled) {
    justify-content: start;
}

.swiper-button-next:not(:disabled):hover, .swiper-button-prev:not(:disabled):hover {
    color: rgba(0, 210, 198, 1);
    transition: color 0.2s ease-in-out;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    background: transparent;
    border: none;
}

.swiper-button-prev-hidden:not(:disabled) {
    display: none;
}

.swiper-button-next-hidden:not(:disabled) {
    display: none;
}

.swiper-pagination {
    padding-top: 20px;
    position: static !important;
}

.swiper-pagination-bullet-active {
    background-color: rgba(0, 210, 198, 1) !important;
}

.hidden {
    display: none;
}

.dropdown-menu.show {
    width: 215px;
}

@media (min-width: 640px) {
    .sect-subtitle {
        font-size: 20px;
    }
}

@media (min-width: 768px) {
    .carousel-wrap {
        padding: 0 50px;
    }
}

@media (min-width: 375px) and (max-width: 960px) {
    .sect-cards-card, .sect-cards a {
        width: 100%;
        max-width: 387px;
    }

    .iot-sect-cards-card {
        width: 100%;
        max-width: 590px !important;
        opacity: 1;
        background-image: linear-gradient(268deg, #00C0DC 0%, #00DFD2 99.59%);
    }

    .iot-sect-cards-card::before {
        position: relative;
        opacity: 1;
        background: #FFFFFF;
    }

    .iot-svg-container {
        background: #FFFFFF;
    }

    .iot-div-text {
        font-size: 15px;

    }

    .iot-div-header {
        font-size: 20px;
    }

    .iot-button-container {
        display: none;
    }

    .sect-container {
        padding: 0 30px;
    }

    .b-refs-buttons {
        width: 100%;
        left: 0;
    }

    .sect-title {
        font-size: 30px;
    }

    .sect {
        padding: 30px 0;
    }
}


@media (min-width: 960px) and (max-width: 1200px) {
    .sect-container {
        padding: 0 60px;
    }

    .slider-title {
        flex-direction: row;
        align-items: center;
        gap: unset;
    }

    .b-refs-buttons {
        width: 100%;
        left: unset;
    }
}

@media (min-width: 1200px) {
    .slider-title {
        flex-direction: row;
        align-items: center;
        gap: unset;
    }
}

.widget-box {
    width: 100vw;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.widget-container {
    max-width: 1440px !important;
    padding: 0 119.5px;
    width: 1000px;
    height: fit-content;
}
