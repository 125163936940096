.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 0 32px 0;
    background-color: #0E100F;
    color: #FFFFFF;
}

.footer-container {
    flex-direction: column;
    gap: 47px;
}

.footer-rights {
    white-space: pre;
    color: #828282;
    transition: color 0.2s ease-in-out;
}

.footer-rights:hover {
    color: #FFFFFF;
}

.footer-nav a {
    transition: color .2s ease-in-out;
}

.footer-nav a:hover {
    color: #00C0DCFF;
}

.footer-resources {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 55px;
}

.footer-resources svg {
    display: flex;
    justify-content: center;
    align-items: center;
    fill: #FFFFFF;
    width: 32px;
    height: 32px;
}

.footer-resources svg:hover {
    fill: #0E100F;
    background: linear-gradient(180deg, rgba(0, 192, 220, 1) 0%, rgba(0, 210, 198, 1) 100%);
    transition: fill 0.2s ease-in-out;
    width: 32px;
    height: 32px;
    border-radius: 5px;
}

.footer-resources svg path {
    align-self: center;
    justify-self: center;
}

.footer-sect {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
    font-size: 14px;
}

@media (min-width: 375px) and (max-width: 960px) {
    .footer-nav {
        width: 50%;
        gap: 30px;
    }

    .footer-sect {
        flex-direction: column;
        gap: 20px;
    }

    .footer-resources {
        gap: 40px;
    }
}
