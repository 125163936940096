.feedback-btn {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.hide {
    display: none;
}

.feedback-carousel-card {
    box-sizing: border-box;
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    position: relative;
    width: 387px !important;
    height: 299px;
    padding: 30px ;
    border-radius: 12px;
    background: #FFFFFF;
    font-weight: 400;
    font-size: 16px;
}

.feedback-carousel-person {
    display: flex;
    width: 100%;
}

.feedback-carousel-person img {
    height: 56px;
}

.feedback-carousel-desc {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
}

.feedback-carousel-desc p,
.feedback-carousel-message {
    margin: 0;
}

.feedback-carousel-message {
    line-height: 24px;
    width: 100%;
}

.feedback-carousel-name {
    color: #0E100F;
    font-size: 20px;
    font-weight: 600;
    white-space: pre;
}

.text-container {
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.message-short {
    width: 100%;
    padding-right: 6px;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100% - 60px);
}

.message-short::-webkit-scrollbar {
    width: 4px;
}

.message-short::-webkit-scrollbar-thumb {
    background: linear-gradient(#00C0DC, #00D2C6), linear-gradient(101deg, #00C0DC, #00D2C6);
    border-radius: 8px;
}

.message-short::-webkit-scrollbar-track {
    background: rgba(0, 210, 198, 0.1);
    border-radius: 8px;
}

.message-show {
    position: absolute;
    bottom: 8px;
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    color: #00C6B4;
    cursor: pointer;
}

.clutch-button {
    display: flex;
    align-items: center;
    justify-self: flex-end;
}

.clutch-button:hover {
    cursor: pointer;
}

.clutch-image {
    width: 50px;
    height: 13px;
    object-fit: cover;
    margin-left: 10px;
}
